.App {
  text-align: center;
}
html{
    font-family:ui-sans-serif,system-ui,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.header {
  background-color: #ffffff; /* 흰색 배경 */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* 그림자 효과 */
  padding: 0.25rem 1.5rem; /* 패딩 값 조정 */
  position: fixed;
  top: 0; /* 화면 상단에 고정 */
  width: 100%; /* 가로 전체 너비로 확장 */
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 3.5rem; /* 로고 아이콘 크기 조정 */
  
}

body{
  margin-top: 4rem;
}

.content-top {
  
  padding: 12rem 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.content {
  
  padding: 8rem 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.bacground-gray{
  background-color: #f1f1f1;
}
.bacground-white{
  background-color: #ffffff;
}
.content-container{
    padding-left : 1.5rem;
    padding-right: 1.5rem;
    margin-left :  1.5rem;
    margin-right :  1.5rem;
    max-width: 1024px;
    width:100%;
    
}

.content-inner-frame{
  display: flex;
  gap: 1.5rem;
  flex-wrap: wrap;
}

.content-inner-box{
  border: 1px solid #e5e7eb;
  flex:1;
  padding: 1.5rem;
  border-radius: .375rem;
  height: 100%;
}

.content-container img{
  width: 10rem;
  padding : 2rem;
}
.font-title{
    font-size: 4rem;
    font-weight: 700;
    padding-bottom: .5rem;
}


.font-subtitle{
  font-size: 1.5rem;
  font-weight: 600;
  padding-bottom: 1.5rem;
  
}

.font-content{
    font-size: 1.2rem;
    
    padding-top: 24px;
    color: #555555;
}

.font-title2{
  font-size: 2.5rem;
  font-weight: 800;
  padding-bottom: .5rem;
}
.font-subtitle2{
  font-size: 1.25rem;
  font-weight: 400;
  padding-bottom: 24px;
  word-break: keep-all;
  color: #555555;
  
}

.box-inner-title{
  font-size: 1.25rem; 
  font-weight: 700;
  padding-bottom: 12px;;
  text-align: left;
}

.box-inner-content{
  font-size: 1rem;
  font-weight: 400;
  text-align: left;
  word-break: keep-all;
  color: #555555;
}


.br-padding{
  margin: 40px 0;
}

/* 테블릿 화면 이하에서 스타일 적용 */
@media only screen and (max-width: 768px) {
  .content {
    padding: 16px; /* 테블릿부터는 작은 패딩 값으로 변경 */
  }
}
